import moondust from '@/services/moondust'

const state = {
  channels: {},
  networks: {},
  terminals: {},

  // Used for cashspot - one object per actor
  terminalLimits: {},
}

const getters = {
  channels: (state, getters, rootState, rootGetters) => state.channels[rootGetters.actorId] ?? [],
  networks: (state, getters, rootState, rootGetters) => state.networks[rootGetters.actorId] ?? [],
  terminals: (state, getters, rootState, rootGetters) => state.terminals[rootGetters.actorId] ?? [],
  terminalLimits: (state, getters, rootState, rootGetters) => state.terminalLimits[rootGetters.actorId] ?? {},

  channelById: (state, getters) => (channelId) => getters.channels.find(c => c.id == channelId),
  networkById: (state, getters) => (networkId) => getters.networks.find(c => c.id == networkId),
  terminalById: (state, getters) => (terminalId) => getters.terminals.find(c => c.id == terminalId),

  networksByChannelId: (state, getters) => (channelId) => getters.networks.filter(c => c.channel_id == channelId),
  terminalsByNetworkId: (state, getters) => (networkId) => getters.terminals.filter(c => c.network_id == networkId),
}

const actions = {

  // Channels

  async getChannelList({ commit, rootGetters }) {
    const actorId = rootGetters.actorId

    const data = await moondust.getChannelList()

    if (data) commit('SET_CHANNEL_LIST', {actorId, channels: data})

    return Promise.resolve()
  },

  async saveChannel({ commit, rootGetters }, channel) {
    const actorId = rootGetters.actorId

    const updatedChannel = (await moondust.saveChannel(channel)).data

    commit('SET_CHANNEL', {actorId, channel: updatedChannel})
    return Promise.resolve(updatedChannel)
  },

  async addChannel({ commit, rootGetters }, channel) {
    const actorId = rootGetters.actorId

    const addedChannel = await moondust.addChannel(channel)

    commit('SET_CHANNEL', {actorId, channel: addedChannel})

    return Promise.resolve(addedChannel)
  },

  async removeChannel({ commit, rootGetters }, channelId) {
    const actorId = rootGetters.actorId

    const removedChannel = await moondust.removeChannel(channelId)

    commit('REMOVE_CHANNEL', {actorId, channelId})

    return Promise.resolve(removedChannel)
  },


  // Networks

  async getNetworkList({ commit, rootGetters }) {
    const actorId = rootGetters.actorId

    const data = await moondust.getNetworkList()

    if (data) commit('SET_NETWORK_LIST', {actorId, networks: data})

    return Promise.resolve()
  },

  async saveNetwork({ commit, rootGetters }, network) {
    const actorId = rootGetters.actorId

    const updatedNetwork = (await moondust.saveNetwork(network)).data

    commit('SET_NETWORK', {actorId, network: updatedNetwork})

    return Promise.resolve(updatedNetwork)
  },

  async addNetwork({ commit, rootGetters }, network) {
    const actorId = rootGetters.actorId

    const addedNetwork = await moondust.addNetwork(network)

    commit('SET_NETWORK', {actorId, network: addedNetwork})

    return Promise.resolve(addedNetwork)
  },

  async removeNetwork({ commit, rootGetters }, networkId) {
    const actorId = rootGetters.actorId

    const removedNetwork = await moondust.removeNetwork(networkId)

    commit('REMOVE_NETWORK', {actorId, networkId})

    return Promise.resolve(removedNetwork)
  },

  // Terminals

  async getTerminalList({ commit, rootGetters }) {
    const actorId = rootGetters.actorId

    const data = await moondust.getTerminalList()

    if (data) commit('SET_TERMINAL_LIST', {actorId, terminals: data})

    return Promise.resolve()
  },

  async saveTerminal({ commit, rootGetters }, terminal) {
    const actorId = rootGetters.actorId

    const updatedTerminal = (await moondust.saveTerminal(terminal)).data

    commit('SET_TERMINAL', {actorId, terminal: updatedTerminal})

    return Promise.resolve(updatedTerminal)
  },

  async addTerminal({ commit, rootGetters }, terminal) {
    const actorId = rootGetters.actorId

    const addedTerminal = await moondust.addTerminal(terminal)

    commit('SET_TERMINAL', {actorId, terminal: addedTerminal})

    return Promise.resolve(addedTerminal)
  },

  async getTerminalLimits({ commit, rootGetters }, terminalId) {
    const actorId = rootGetters.actorId

    const limits = (await moondust.getTerminalLimits(terminalId)).data

    commit('SET_TERMINAL_LIMITS', {actorId, limits})

    return Promise.resolve(limits)
  },

  async removeTerminal({ commit, rootGetters }, terminalId) {
    const actorId = rootGetters.actorId

    const removedTerminal = await moondust.removeTerminal(terminalId)

    commit('REMOVE_TERMINAL', {actorId, terminalId})

    return Promise.resolve(removedTerminal)
  },

  clearState: ({ commit }) => {

    commit('CLEAR_STATE')

    return Promise.resolve()
  }
}

const mutations = {

  // Channels

  SET_CHANNEL_LIST(state, {actorId, channels}) {
    state.channels = { ...state.channels, [actorId]: channels }
  },

  SET_CHANNEL(state, {actorId, channel}) {
    const actorChannels = state.channels[actorId] || []
    const foundChannelIndex = actorChannels.findIndex(c => c.id == channel.id)

    if (foundChannelIndex == -1) {
      actorChannels.push(channel)
    }
    else {
      actorChannels[foundChannelIndex] = channel
    }

    state.channels = { ...state.channels, [actorId]: actorChannels }
  },

  REMOVE_CHANNELS(state, {actorId}) {
    state.channels[actorId] = []
  },

  REMOVE_CHANNEL(state, {actorId, channelId}) {
    const actorChannels = state.channels[actorId] || []
    const foundChannelIndex = actorChannels.findIndex(c => c.id == channelId)

    if (foundChannelIndex > -1) {
      actorChannels.splice(foundChannelIndex, 1)

      state.channels = { ...state.channels, [actorId]: actorChannels }
    }
  },

  REMOVE_CHANNEL_LISTS(state, actorId) {
    state.channels[actorId] = []
  },


  // Networks

  SET_NETWORK_LIST(state, {actorId, networks}) {
    state.networks = { ...state.networks, [actorId]: networks }
  },

  SET_NETWORK(state, {actorId, network}) {
    const actorNetworks = state.networks[actorId] || []
    const foundNetworkIndex = actorNetworks.findIndex(c => c.id == network.id)

    if (foundNetworkIndex == -1) {
      actorNetworks.push(network)
    }
    else {
      actorNetworks.splice(foundNetworkIndex, 1, network)
    }

    //state.networks[actorId] ||= []
    //const length = state.networks[actorId].length
    //state.networks[actorId].splice(0, length, ...actorNetworks)
    state.networks = { ...state.networks, [actorId]: actorNetworks }
  },

  REMOVE_NETWORKS(state, {actorId}) {
    state.networks[actorId] = []
  },

  REMOVE_NETWORK(state, {actorId, networkId}) {
    const actorNetworks = state.networks[actorId] || []
    const foundNetworkIndex = actorNetworks.findIndex(c => c.id == networkId)

    if (foundNetworkIndex > -1) {
      actorNetworks.splice(foundNetworkIndex, 1)

      state.networks = { ...state.networks, [actorId]: actorNetworks }
    }
  },

  REMOVE_NETWORK_LISTS(state, actorId) {
    state.networks[actorId] = []
  },


  // Terminals

  SET_TERMINAL_LIST(state, {actorId, terminals}) {
    state.terminals = { ...state.terminals, [actorId]: terminals }
  },

  SET_TERMINAL(state, {actorId, terminal}) {
    const actorTerminals = state.terminals[actorId] || []
    const foundTerminalIndex = actorTerminals.findIndex(c => c.id == terminal.id)

    if (foundTerminalIndex == -1) {
      actorTerminals.push(terminal)
    }
    else {
      actorTerminals[foundTerminalIndex] = terminal
    }

    state.terminals = { ...state.terminals, [actorId]: actorTerminals }
  },

  REMOVE_TERMINALS(state, {actorId}) {
    state.terminals[actorId] = []
  },

  REMOVE_TERMINAL(state, {actorId, terminalId}) {
    const actorTerminals = state.terminals[actorId] || []
    const foundTerminalIndex = actorTerminals.findIndex(c => c.id == terminalId)

    if (foundTerminalIndex > -1) {
      actorTerminals.splice(foundTerminalIndex, 1)

      state.terminals = { ...state.terminals, [actorId]: actorTerminals }
    }
  },

  REMOVE_TERMINAL_LISTS(state, actorId) {
    state.terminals[actorId] = []
  },

  SET_TERMINAL_LIMITS(state, {actorId, limits}) {
    const actorTerminalLimits = state.terminalLimits[actorId] || {}
    const terminalLimits = {...actorTerminalLimits, ...limits}
    state.terminalLimits = { ...state.terminalLimits, [actorId]: terminalLimits }
  },

  CLEAR_STATE(state) {
    state.channels = {}
    state.networks = {}
    state.terminals = {}
    state.terminalLimits = {}
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}


