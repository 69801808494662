import Vue from 'vue'
import VueMeta from 'vue-meta'
//import GAuth from 'vue-google-oauth2'
import { VueMaskDirective, VueMaskFilter } from 'v-mask'
import Formatter from '@/plugins/formatter'
import { createRouter }  from '@/router/index'
import { createStore }   from '@/store/index'
import { createVuetify } from '@/plugins/vuetify'
import App from '@/App.vue'

Vue.config.productionTip = false

Vue.use(Formatter)
Vue.use(VueMeta)
Vue.directive('mask', VueMaskDirective)
Vue.filter('VMask', VueMaskFilter)

Vue.mixin({
  computed: {
    isCashSpot: () => location.hostname == 'accesscashspot.com',
    isApiDocs: () => /^apidocs\.accesscardnet\.com$/.test(location.hostname),
    isCardNet: () => /^(?:(?!apidocs\.))accesscardnet\.com$/.test(location.hostname),
  },

  metaInfo: {
    meta: [
      {
        vmid: 'viewport',
        name: 'viewport',
        content: navigator.userAgent.match(/mobile/i)
          ? 'width=device-width, initial-scale=1'
          : 'width=1280'
      },
    ]
  },
})

// TODO/FIXME Convert to async dynamically retrieved components
// Register role components
//for (const name of ['allow-auth', 'manage-user', 'create-user']) {
//  Vue.component(name, () => import(`@/components/Roles/${name}`))
//}

if (!Array.prototype.last) { Array.prototype.last = function() { return this[this.length - 1] } }
if (!Array.prototype.first) { Array.prototype.first = function() { return this[0] } }
String.prototype.capitalize = function() { return this.charAt(0).toUpperCase() + this.slice(1) }

export function createApp() {
  const store = createStore()
  const router = createRouter(store)
  const vuetify = createVuetify()

  store.router = router

  const app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  })

  return { app, router, store }
}
