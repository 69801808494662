import PhoneNumber from 'awesome-phonenumber'

const Formatter = {

  install(Vue) {
    Vue.prototype.formatPhone = (phone) => phone ? PhoneNumber(phone.replace(/\D/gi, ''), 'US').getNumber('national') : undefined
    Vue.prototype.formatCurrency = (amount, code) => new Intl.NumberFormat('en-US', {style: 'currency', currency: code}).format(amount)
  },
}

export default Formatter
