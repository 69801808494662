import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@/store/modules/auth'
import Account from '@/store/modules/account'
import Actor from '@/store/modules/actor'
import Settings from '@/store/modules/settings'
import User from '@/store/modules/user'
import Transaction from '@/store/modules/transaction'
import Channel from '@/store/modules/channel'
import Application from '@/store/modules/application'
import createPersistedState from 'vuex-persistedstate'
import ActiveActorPlugin from '@/store/plugins/active-actor.js'
import moondust from '@/services/moondust'
import Constants from '@/constants'
import Console from '@/console'

Vue.use(Vuex)

export function createStore() {

  const store = new Vuex.Store({

    strict: process.env.NODE_ENV !== 'production',

    state: () => ({
      envName: undefined,
      user: undefined,
      accountId: undefined,
      actorId: undefined,
      identityId: undefined,
    }),

    getters: {

      actorId: (state) => state.actorId,

      canAccessApiDocs: function(state, getters, rootState, rootGetters) {
        return rootGetters['User/hasRoleName'](Constants.roleNames.apiSpec)
      },

      canManageContacts: function(state, getters, rootState, rootGetters) {
        return rootGetters['User/roleNames'].some(roleName => roleName == Constants.roleNames.manageContacts)
      },

      isAuthenticated: function(state) {
        if (window.self !== window.top) {
          Console.log({name: 'isAuthenticated', mode: 'iframe', actorId: state.actorId, prism_user_id: state.user?.prism_sso?.user_id, return: !!state.user?.prism_sso?.user_id && !!state.actorId })
        }
        else {
          Console.log({name: 'isAuthenticate', mode: 'NOT iframe', user_id: state.user?.id, return: !!state.user?.id })
        }

        return window.self !== window.top
        ? !!state.user?.prism_sso?.user_id && !!state.actorId
        : !!state.user?.id
      },
    },

    actions: {

      openApiSpec() {
        return moondust.getOpenApiSpec().then(r => r.data)
      },

      setPersistedState ({ state, commit }, uniqueIdentifier) {

        // Setup persistant storage vuex <-> localStorage
        const key = uniqueIdentifier
        Console.log(`Loading localStorage (${key}) into vuex store`)
        createPersistedState({ key: key })(this)

        // If actorId exists in persisted state,
        // commit so that ActiveActor plugin will initiate actor setting process
        //const persistedActorId = state.actorId
        //moondust.setActorId(persistedActorId)
        const persistedActorId = state.actorId
        if (persistedActorId) commit('ACTORID', persistedActorId)

        return Promise.resolve()
      },

      async clearState ({ commit, dispatch }) {

        // Clear state from this module

        commit('USER', undefined)
        commit('ACCOUNTID', undefined)
        commit('ACTORID', undefined)
        commit('IDENTITYID', undefined)

        // Clear state from all sub modules

        return Promise.all([
          dispatch('Settings/clearState',    undefined, {root: true}),
          dispatch('User/clearState',        undefined, {root: true}),
          dispatch('Auth/clearState',        undefined, {root: true}),
          dispatch('Transaction/clearState', undefined, {root: true}),
          dispatch('Channel/clearState',     undefined, {root: true}),
          dispatch('Application/clearState', undefined, {root: true}),
        ])
      }
    },

    mutations: {
      USER       (state, user) { state.user = user },
      IDENTITYID (state, identityId) { state.identityId = identityId },
      ACTORID    (state, actorId) { state.actorId = actorId },
      ACCOUNTID  (state, actorId) { state.accountId = actorId },
      PEOID      (state, peoId) { state.peoId = peoId },
      SSOKEY     (state, ssoKey) { state.ssoKey = ssoKey },
      ENVNAME    (state, envName) { state.envName = envName },

      CLEAR_STATE (state) {
        state.envName = undefined
        state.user = undefined
        state.accountId = undefined
        state.actorId = undefined
        state.identityId = undefined
      },
    },

    plugins: [
      ActiveActorPlugin,
    ],

    modules: {
      Auth,
      Actor,
      Account,
      Settings,
      User,
      Transaction,
      Channel,
      Application,
    }
  })

  return store
}

