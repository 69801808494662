import moondust from '@/services/moondust'
import Console from '@/console'
import _ from 'lodash'

const state = {
  users: {},
  usersByUsername: {},
  roleNames: {},
  orphanedUserLayers: {},
}

const getters = {
  users: (state, getters, rootState, rootGetters) => state.users[rootGetters.actorId] ?? [],
  usersByUsername: (state, getters, rootState, rootGetters) => state.usersByUsername[rootGetters.actorId] ?? [],

  userByUsername: (state, getters) => (username) => getters.usersByUsername[username],
  userByAccountId: (state, getters) => (accountId) => getters.users.find(user => user.account_id == accountId),
  identitiesByAccountId: (state, getters) => (accountId) => getters.userByAccountId(accountId)?.identities.map(identityArray => Object.fromEntries(_.chunk(identityArray, 2))),
  actorsByAccountId: (state, getters) => (accountId) => getters.userByAccountId(accountId)?.actors,

  roleNames: (state, getters, rootState, rootGetters) => state.roleNames[rootGetters.actorId] ?? [],
  hasRoleName: (state, getters, rootState) => (name) => {
    const roleNamesFromSignIn = rootState.user.accounts
      .flatMap(a => a.actors)
      .flatMap(act => act.roles)
      .map(r => Object.fromEntries(_.chunk(['id', r[0], 'name', r[1], ...r.slice(2)], 2)).name)

    const roleNames = [...getters.roleNames, ...roleNamesFromSignIn]

    return roleNames.some(roleName => roleName == name)
  },
  rolesByName: (state, getters, rootState) => name => {
    const rolesFromSignIn = rootState.user.accounts
      .flatMap(a => a.actors)
      .flatMap(act => act.roles)
      .map(r => Object.fromEntries(_.chunk(['id', r[0], 'name', r[1], ...r.slice(2)], 2)))

    return rolesFromSignIn.filter(r => r.name == name)
  },

  clerkTerminalId(state, getters) {
    return getters.rolesByName('clerk').first()?.terminal_id
  },


  orphanedUserLayers: (state, getters, rootState, rootGetters) => state.orphanedUserLayers[rootGetters.actorId] ?? {},

  usersByTerminalId: (state, getters) => (terminalId) => {
    return getters.users.filter(u => {
      return u.roles.some(r => {
        return r.name == 'clerk' && r.values.includes('terminal_id') && r.values.includes(terminalId)
      })
    })
  },

  terminalsByAccountId: (state, getters) => (accountId) => getters.userByAccountId(accountId)
    ?.roles.filter(r => r.name == 'clerk' && r.values.includes('terminal_id'))
    .map(r => Object.fromEntries(_.chunk(r.values, 2)))
    .filter(v => v.terminal_id)
    .map(v => v.terminal_id),

  isUserActive: (state, getters) => (accountId) => getters.userByAccountId(accountId)?.roles.some(r => r.name == 'allow-auth' && r.enabled),
  isUserSuspended: (state, getters) => !getters.isUserActive,

  isPortalUser: (state, getters) => (accountId) => getters.userByAccountId(accountId)?.roles.some(r => r.name == 'channels' || r.name == 'networks'),
  isClerkUser: (state, getters) => (accountId) => getters.userByAccountId(accountId)?.roles.some(r => r.name == 'clerk'),
}

const actions = {

  async getOrphanedUserLayers ( { commit, rootGetters } ) {
    try {
      const actorId = rootGetters.actorId

      const data = await moondust.getOrphanedUserLayers()
      if (data) commit('SET_ORPHANED_USER_LAYERS', {actorId: actorId, data: data})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve orphaned user layers', error)
    }
  },

  async getUsers ( { commit, rootGetters } ) {
    try {
      const actorId = rootGetters.actorId

      const users = await moondust.users()
      if (users) commit('SET_USERS', {actorId, users})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve users', error)
    }
  },

  async getUser ( { commit, rootGetters }, { accountId } ) {
    try {
      const actorId = rootGetters.actorId

      const user = await moondust.user(accountId)
      if (user) commit('SET_USER', {actorId, user})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve users', error)
    }
  },

  async addUser({ commit, rootGetters }, user) {
    const actorId = rootGetters.actorId

    const addedUser = (await moondust.addUser(user)).data

    commit('SET_USER', {actorId, user: addedUser})

    return Promise.resolve(addedUser)
  },

  async saveUser({ commit, rootGetters }, user) {
    const actorId = rootGetters.actorId

    const updatedUser = (await moondust.saveUser(user)).data

    commit('SET_USER', {actorId, user: updatedUser})

    return Promise.resolve(updatedUser)
  },


  async removeUser ( { commit, rootGetters }, accountId ) {
    try {
      const actorId = rootGetters.actorId

      await moondust.removeUser(accountId)

      commit('REMOVE_USER', {actorId, accountId})

      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not remove user', error)
    }
  },

  async getRoleNames ( { commit, rootGetters } ) {
    try {
      const actorId = rootGetters.actorId

      const data = await moondust.roleNames()
      if (data) commit('SET_ROLE_NAMES', {actorId: actorId, data: data})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve role names', error)
    }
  },

  async enableDisableIdentity( { commit, rootGetters }, { identityId, identityActorId, accountId, enable } ) {
    const actorId = rootGetters.actorId

    await moondust.enableDisableIdentity({identityId, identityActorId, enable})
    const user = await moondust.user(accountId)

    commit('SET_USER', {actorId: actorId, data: user})

    return Promise.resolve()
  },

  async updateRole( { commit, rootGetters }, { accountId, roleActorId, roleId, enable, ...updates } ) {
    const actorId = rootGetters.actorId

    await moondust.updateRole({roleActorId, roleId, enable, ...updates})
    const user = await moondust.user(accountId)

    commit('SET_USER', {actorId: actorId, data: user})

    return Promise.resolve()
  },

  async updateAccount( { commit, rootGetters }, { accountId, accountName } ) {

    const actorId = rootGetters.actorId

    await moondust.updateAccount({accountId, accountName})
    const user = await moondust.user(accountId)

    commit('SET_USER', {actorId: actorId, data: user})

    return Promise.resolve()
  },

  async clearState ({ commit }) {
    commit('CLEAR_STATE')

    return Promise.resolve()
  },
}

const mutations = {

  REMOVE_USER(state, {actorId, accountId}) {
    const users = state.users[actorId].filter(u => u.account_id != accountId)
    state.users = { ...state.users, [actorId]: users }
  },

  SET_USER(state, {actorId, user}) {
    const accountId = user.account_id
    const actorUsers = state.users[actorId]
    const foundUserIndex = actorUsers.findIndex(u => u.account_id == accountId)

    if (foundUserIndex == -1) {
      actorUsers.push(user)
    }
    else {
      actorUsers[foundUserIndex] = user
    }

    state.users = { ...state.users, [actorId]: actorUsers }
  },

  SET_USERS(state, {actorId, users}) {
    state.users = { ...state.users, [actorId]: users}

    //users.map(u => u.identities.map(i => Object.fromEntries(_.chunk(i, 2))).filter(i => i.username).filter(i => i.length > 0)

    //state.usersByUsername = {
    //  ...state.usersByUsername,
    //  [actorId]: Object.fromEntries(

    //               users.map(u => {
    //                 const usernames = u.identities.map(i => Object.fromEntries(_.chunk(i, 2)).username)
    //                 return usernames.map(username => [username, u])
    //               })
    //  )
    //}
  },

  SET_ROLE_NAMES(state, {actorId, data}) {
    state.roleNames = { ...state.roleNames, [actorId]: data}
  },

  SET_ORPHANED_USER_LAYERS(state, {actorId, data}) {
    state.orphanedUserLayers = { ...state.orphanedUserLayers, [actorId]: data}
  },

  CLEAR_STATE(state) {
    state.users = {}
    state.roleNames = {}
    state.orphanedUserLayers = {}
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
