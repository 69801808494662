import Vue from 'vue';
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import moondust from '@/services/moondust'

Vue.use(VueRouter);
Vue.use(VueMeta);

const UNIQUE_SIGNED_IN_IDENTIFIER_KEY = 'UNIQUE_SIGNED_IN_IDENTIFIER'

export function createRouter(store) {

  const beforeEach = async function(to, from, next) {

    // Assume requiresAuth == true, unless specified false in meta for route
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth ?? true)

    moondust.setApiUrl(
      /accesscardnet\.com$/.test(location.hostname)
      ? 'https://accesscardnet.com'
      : 'https://accesscashspot.com'
    )

    const activeUniqueIdentifier = localStorage.getItem(UNIQUE_SIGNED_IN_IDENTIFIER_KEY)
    if (activeUniqueIdentifier) {
      store.dispatch('setPersistedState', activeUniqueIdentifier)
    }

    const isAuthenticated = store.getters.isAuthenticated

    if (requiresAuth) {
      if (isAuthenticated) {
        return next()
      }
      else {
        return next({ name: 'sign-in' })
      }
    }
    else {
      return next()
    }
  }

  let routes = []

  switch (location.hostname) {

    case 'accesscashspot.com':

      routes.push(
        {
          path: '/signout',
          name: 'sign-out',
          props: true,
          component: () => import('../views/SignOut.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/signin',
          name: 'sign-in',
          component: () => import('../views/SignIn.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/',
          name: 'signed-in',
          component: () => import('../views/PosSignedIn.vue'),
          redirect: '/stepper',

          children: [
            {
              path: 'stepper',
              name: 'stepper',
              component: () => import('../views/Stepper.vue'),
              meta: {
                requiresAuth: false
              },
            },
            {
              path: 'enter-phone',
              name: 'enter-phone',
              component: () => import('../views/EnterPhone.vue'),
              meta: {
                requiresAuth: false
              },
            },
            {
              path: 'enter-pin',
              name: 'enter-pin',
              props: true,
              component: () => import('../views/EnterPin.vue'),
            },
            {
              path: 'load',
              name: 'load-or-cashback',
              component: () => import('../views/Load.vue'),
            },
            {
              path: 'cashback',
              name: 'cashback',
              component: () => import('../views/Cashback.vue'),
            },
          ],
        }
      )
      break

    case 'apidocs.accesscardnet.com':

      routes.push(
        {
          path: '/signout',
          name: 'sign-out',
          props: true,
          component: () => import('../views/SignOut.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/signin',
          name: 'sign-in',
          component: () => import('../views/SignIn.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/',
          name: 'apidocs',
          component: () => import('../views/ApiDocs.vue'),
          meta: {
            requiresAuth: true,
          },
        }
      )
      break

    default:

      routes.push(

        {
          path: '/signedin-redirect',
          name: 'signedin-redirect',
          component: () => import('../views/SignedInRedirect.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/signout',
          name: 'sign-out',
          props: true,
          component: () => import('../views/SignOut.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/oauth-signout',
          name: 'oauth-sign-out',
          props: true,
          component: () => import('../views/OAuthSignOut.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/signin',
          name: 'sign-in',
          component: () => import('../views/SignIn.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/',
          name: 'signed-in',
          component: () => import('../views/SignedIn.vue'),
          redirect: '/channels',

          children: [
            {
              path: '/channels',
              name: 'channels',
              component: () => import('../views/Channels.vue'),
              children: [
                {
                  path: ':channel_id',
                  name: 'channel',
                  component: () => import('../views/Channel.vue'),
                  children: [
                    {
                      path: 'networks/:network_id',
                      name: 'network',
                      component: () => import('../views/Network.vue'),
                      children: [
                        {
                          path: 'terminals/:terminal_id',
                          name: 'terminal',
                          component: () => import('../views/Terminal.vue'),
                          children: [
                            {
                              path: ':account_id',
                              name: 'terminal-user',
                              component: () => import('../components/User.vue'),
                            },
                          ]
                        }
                      ]
                    }
                  ],
                      //    children: [
                      //      {
                      //        path: ':terminals',
                      //        name: 'terminals',
                      //        component: () => import('../views/Terminals.vue'),
                      //        children: [
                      //          {
                      //            path: ':terminal_id',
                      //            name: 'terminal',
                      //            component: () => import('../views/Terminal.vue'),
                      //            children: [
                      //              {
                      //                path: ':users',
                      //                name: 'terminal-users',
                      //                component: () => import('../views/Users.vue'),
                      //                props: {
                      //                  'terminal-user': true
                      //                },
                      //              },
                      //            ],
                      //          },
                      //        ],
                      //      },
                      //    ],
                      //  },
                      //],
                },
              ],
            },
            {
              path: '/users',
              name: 'users',
              component: () => import('../views/Users.vue'),
              children: [
              //  {
              //    path: 'overview',
              //    name: 'users-overview',
              //    component: () => import('../components/UsersOverview.vue'),
              //  },
              //  {
              //    path: 'new',
              //    name: 'new-user',
              //    component: () => import('../components/NewUser.vue'),
              //  },
                {
                  path: ':account_id',
                  name: 'user',
                  component: () => import('../components/User.vue'),

                  children: [
                    {
                      path: ':terminal_id',
                      name: 'assigned-terminal',
                      component: () => import('../views/Terminal.vue'),
                    }
                  ]
                },
              ],
            },
            {
              path: '/applications',
              name: 'applications',
              component: () => import('../views/Applications.vue'),
              children: [
                {
                  path: ':application_id',
                  name: 'application',
                  component: () => import('../views/Application.vue'),
                },
              ],
            },
            {
              path: 'apidocs',
              name: 'apidocs',
              component: () => import('../views/ApiDocs.vue'),
              meta: {
                requiresAuth: true,
              },
            }
          ],
        }
      )
      break
  }

  routes.push(
    {
      path: '*',
      component: () => import('../views/NotFound.vue'),
    }
  )

  const router = new VueRouter({
    mode: 'history',
    routes: routes
  })

  router.beforeEach(beforeEach)

  // Assign unauthorized error catcher,
  // which will trigger if Moondust returns 401 or 403
  moondust.setInvalidAuthInterceptor(async ({error}) => {

    // If in iframe, continue, we want a 401 to redirect to sign-out

    // If not in iframe, do not redirect to sign-out if explicitely trying to sign-in
    if (error.response.config.url == moondust.authPath) {
      return
    }

    // sign-out component dispatches Auth/signOut action
    // Why? Because if someone visits sign-out, 
    // it needs to go through the proper signOut procedure
    // We're making use of that expected flow here
    router.push( {name: 'sign-out', params: {error: error, requestedUrl: window.location.href}} )
  })

  return router
}

//import Vue from 'vue'
//import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
//
//Vue.use(VueRouter)
//
//const routes = [
//  {
//    path: '/',
//    name: 'Home',
//    component: Home
//  },
//  {
//    path: '/about',
//    name: 'About',
//    // route level code-splitting
//    // this generates a separate chunk (about.[hash].js) for this route
//    // which is lazy-loaded when the route is visited.
//    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//  }
//]
//
//const router = new VueRouter({
//  mode: 'history',
//  base: process.env.BASE_URL,
//  routes
//})
//
//export default router
