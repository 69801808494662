import axios from 'axios';

const api = axios.create({
  withCredentials: true,
  headers: {
    'Accept':       'application/json',
    'Content-Type': 'application/json'
  }
})

//api.interceptors.response.use(
//  (response) => response,
//  (error) => { return Promise.reject(
//    return Promise.reject(error.response);
//  }
//)

window.axios = api

export default {

  setInvalidAuthInterceptor(cb) {
    window.axios.interceptors.response.use(
      response => response,
      async error => {
        if (error.message == 'Network Error') {
          error.message = 'Network Error: Server is not accessible. Are you connected to the internet?'
        }
        else if (401 === error.response?.status) {
          await cb({error})
        }

        if (!error.message) {
          error.message = 'Undefined Error'
        }

        throw error;
      }
    )
  },

  setApiUrl(url) {
    api.defaults.baseURL = url
  },

  setActorId(actorId) {
    api.defaults.headers.common['As-Actor-Id'] = actorId
  },

  async postAuth(credentials) {
    const res = await api.post('/api/auth', credentials)
    //this.setJwt(res.data.jwt)
    return res.data
  },

  async signOut() {
    return (await api.post('/api/signout')).data
  },

  async getOrphanedUserLayers() {
    return (await api.get('/api/users/orphaned-layers')).data
  },

  async authPhone({phone, transactionType, transactionAmount}) {
    return await api.post(`/api/auth/${ phone }`, {transactionType, transactionAmount})
  },

  async authVerifyPhone({ phone, code, transactionType, transactionAmount}) {
    return await api.patch(`/api/auth/${ phone }`, {code, transactionType, transactionAmount})
  },

  async findUsername(username) {
    return (await api.get('/api/registeredusers', { params: {username: username} })).data
  },

  async getSettings() {
    return (await api.get('/api/settings/blueprint')).data
  },

  async saveSettings(settings) {
    return (await api.patch('/api/settings/blueprint', settings)).data
  },

  async confirmPendingUpdate(beacon) {
    return (await api.put(`/api/confirm-pending-update/${beacon}`)).data
  },

  async createUser(options) {
    return (await api.post('/api/registeredusers', options)).data
  },

  async removeUser(accountId) {
    return (await api.delete(`/api/users/${accountId}`)).data
  },

  async sendConfirmationCode(name) {
    return (await api.post('/api/confirmation-codes', { name: name })).data
  },

  async confirmConfirmationCode(code) {
    return (await api.put(`/api/confirmation-codes/${code}`)).data
  },

  async transferFunds({transactionType, transactionAmount, phone}) {
    return await api.post('/api/transactions', { type: transactionType, amount: transactionAmount, phone: phone })
  },

  async users() {
    return (await api.get('/api/users')).data
  },

  async user(accountId) {
    return (await api.get(`/api/users/${accountId}`)).data
  },

  async roleNames() {
    return (await api.get('/api/role-names')).data
  },

  async enableDisableIdentity({identityId, identityActorId, enable}) {
    return (await api.patch(`/api/identities/${identityId}`,
      {
        identityActorId,
        disabled: !enable
      }
      )
    ).data
  },

  async updateRole({roleActorId, roleId, enable, ...updates}) {
    return (await api.patch(`/api/users/${roleActorId}/roles/${roleId}`,
      {
        disabled: !enable,
        ...updates,
      }
    )).data
  },

  async updateAccount({accountId, accountName}) {
    return (await api.patch(`/api/users/${accountId}`,
      {
        accountName,
      }
    )).data
  },

  async clientNameSearch(search) {
    return (await api.get(`/api/clients?search=${search}`)).data
  },

  async contactSearch({search, labelIds, typeIds}) {
    const types  = typeIds?.join(',')
    const labels = labelIds?.join(',')

    let params = new URLSearchParams()
    params.append("search", search)

    if (types)  params.append("types", types)
    if (labels) params.append("labels", labels)

    return (await api.get('/api/contacts', { params })).data
  },

  async getClientList() {
    return (await api.get('/api/clients')).data
  },

  async clientContacts(clientId) {
    return (await api.get(`/api/clients/${clientId}/contacts`)).data
  },

  async getContactLabels() {
    return (await api.get('/api/contacts/labels')).data
  },

  async getContactLabel(labelId) {
    return (await api.get(`/api/contacts/labels/${labelId}`)).data
  },

  async getContactTypes() {
    return (await api.get('/api/contacts/types')).data
  },

  async getContactType(typeId) {
    return (await api.get(`/api/contacts/types/${typeId}`)).data
  },

  async getChannelList() {
    return (await api.get('/api/channels')).data
  },

  async saveChannel(channel) {
    return await api.put(`/api/channels/${channel.id}`, channel)
  },

  async addChannel(channel) {
    return (await api.post(`/api/channels`, channel)).data
  },

  async removeChannel(channelId) {
    return (await api.delete(`/api/channels/${channelId}`)).data
  },

  async getApplicationList() {
    return (await api.get('/v2/applications')).data
  },

  async getNetworkList() {
    return (await api.get('/api/networks')).data
  },

  async saveNetwork(network) {
    return await api.put(`/api/networks/${network.id}`, network)
  },

  async addNetwork(network) {
    return (await api.post(`/api/networks`, network)).data
  },

  async removeNetwork(networkId) {
    return (await api.delete(`/api/networks/${networkId}`)).data
  },

  async getTerminalList() {
    return (await api.get('/api/terminals')).data
  },

  async saveTerminal(terminal) {
    return await api.put(`/api/terminals/${terminal.id}`, terminal)
  },

  async addTerminal(terminal) {
    return (await api.post(`/api/terminals`, terminal)).data
  },

  async addUser(user) {
    return await api.post(`/api/users`, user)
  },

  async saveUser(user) {
    return await api.put(`/api/users/${user.account_id}`, user)
  },

  async removeTerminal(terminalId) {
    return (await api.delete(`/api/terminals/${terminalId}`)).data
  },

  async getTerminalLimits(terminalId) {
    return await api.get(`/api/terminals/${terminalId}/limits`)
  },

  getOpenApiSpec() {
    return api.get(`/api/openapi`)
  },
}
