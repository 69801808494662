import moondust from '@/services/moondust'

const state = {
  applications: {},
}

const getters = {
  applications: (state, getters, rootState, rootGetters) => state.applications[rootGetters.actorId] ?? [],

  applicationById: (state, getters) => (applicationId) => getters.applications.find(a => a.id == applicationId),
}

const actions = {

  // Applications

  async getApplicationList({ commit, rootGetters }) {
    const actorId = rootGetters.actorId
  
    const data = await moondust.getApplicationList()
  
    if (data) commit('SET_APPLICATION_LIST', {actorId, applications: data})
  
    return Promise.resolve()
  },
  
  async saveApplication({ commit, rootGetters }, application) {
    const actorId = rootGetters.actorId
  
    const updatedApplication = (await moondust.saveApplication(application)).data
  
    commit('SET_APPLICATION', {actorId, application: updatedApplication})
    return Promise.resolve(updatedApplication)
  },
  
  async addApplication({ commit, rootGetters }, application) {
    const actorId = rootGetters.actorId
  
    const addedApplication = await moondust.addApplication(application)
  
    commit('SET_APPLICATION', {actorId, application: addedApplication})
  
    return Promise.resolve(addedApplication)
  },
  
  async removeApplication({ commit, rootGetters }, applicationId) {
    const actorId = rootGetters.actorId
  
    const removedApplication = await moondust.removeApplication(applicationId)
  
    commit('REMOVE_APPLICATION', {actorId, applicationId})
  
    return Promise.resolve(removedApplication)
  },
}

const mutations = {

  // Applications

  SET_APPLICATION_LIST(state, {actorId, applications}) {
    state.applications = { ...state.applications, [actorId]: applications }
  },

  SET_APPLICATION(state, {actorId, application}) {
    const actorApplications = state.applications[actorId] || []
    const foundApplicationIndex = actorApplications.findIndex(a => a.id == application.id)

    if (foundApplicationIndex == -1) {
      actorApplications.push(application)
    }
    else {
      actorApplications[foundApplicationIndex] = application
    }

    state.applications = { ...state.applications, [actorId]: actorApplications }
  },

  REMOVE_APPLICATIONS(state, {actorId}) {
    state.applications[actorId] = []
  },

  REMOVE_APPLICATION(state, {actorId, applicationId}) {
    const actorApplications = state.applications[actorId] || []
    const foundApplicationIndex = actorApplications.findIndex(a => a.id == applicationId)

    if (foundApplicationIndex > -1) {
      actorApplications.splice(foundApplicationIndex, 1)

      state.applications = { ...state.applications, [actorId]: actorApplications }
    }
  },

  REMOVE_APPLICATION_LISTS(state, actorId) {
    state.applications[actorId] = []
  },

  CLEAR_STATE(state) {
    state.applications = {}
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
