import moondust from '@/services/moondust.js'

const state = {
  settings: {}
}

const actions = {

  async getSettings( { commit } ) {
    const data = await moondust.getSettings()
    commit('SET_SETTINGS', data)
    return Promise.resolve(data)
  },

  async saveSettingsToServer( { state, commit } ) {
    const data = await moondust.saveSettings(state.settings)
    commit('SET_SETTINGS', data)
    return Promise.resolve(data)
  },

  clearState: ({ commit }) => {
    commit('REMOVE_SETTINGS')

    return Promise.resolve()
  },
}

const mutations = {

  SET_SETTINGS(state, settings) {
    state.settings = settings
  },

  REMOVE_SETTINGS(state) {
    state.settings = {}
  }
}

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state,
  actions,
  mutations,
}

