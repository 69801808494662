const getters = {
  actor: (state, getters, rootState, rootGetters) => rootGetters['Account/account']?.actors?.find(a => a.id == rootState.actorId),

  id: (state, getters) => getters._actor?.id,

  roles: (state, getters) => getters._actor?.roles,
}

const actions = {
  clearState: () => Promise.resolve(),
}

export default {
  namespaced: true,
  getters,
  actions,
}

