import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
  customProperties: true,
})

export function createVuetify() {
  return new Vuetify({

    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: colors.blue.darken3,
          secondary: '#FAF9F5',
          info: colors.yellow.darken1,
          accent: colors.purple,
          success: colors.blue,
          warning: colors.orange,
          error: colors.red,

          button: colors.blue.lighten1,
          headerText: '#FAF9F5',
          primaryOffline: colors.brown.darken2
        }
      }
    }
  })
}

