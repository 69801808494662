import moondust from '@/services/moondust'
//import { createRouter }  from '@/router/index'
import Console from '@/console'

const UNIQUE_SIGNED_IN_IDENTIFIER_KEY = 'UNIQUE_SIGNED_IN_IDENTIFIER'

const state = {
  foundEmployeeDigests: [],
  takenUsernames: {},
  signoutTriggered: false,

  loggedInWithGoogle: false,
  loggedInWithPassword: false,
}

const getters = {

  isUsernameAvailable: state => v => {
    return !state.takenUsernames[v]
  },
}

const actions = {

  async setActorId({ commit, dispatch }, actorId) {
    commit('ACTORID', actorId, {root: true})

    await dispatch('Settings/saveSettingsToServer', undefined, {root: true})
  },

  async sendConfirmationCode(n, name) {
    await moondust.sendConfirmationCode(name)
  },

  async confirmConfirmationCode(n, code) {
    await moondust.confirmConfirmationCode(code)
  },

  async isUsernameTaken ( { commit }, username ) {

    try {
      await moondust.findUsername(username)
      Console.error('Username is taken', username)
      commit('SET_TAKEN_USERNAME', username)
      return true
    }
    catch(error) {
      Console.log('Username is available', username)
      commit('REMOVE_TAKEN_USERNAME', username)
      return false
    }
  },

  async signIn ({ commit, dispatch }, userOrCredentials) {

    try {
      await dispatch('clearState')

      const user = userOrCredentials.id && userOrCredentials.account ?
        userOrCredentials :
        await moondust.postAuth(userOrCredentials)

      commit('USER', user, {root: true})

      let accountId
      let actorId
      let identityId = user.id
      let settings

      try {
        settings = await dispatch('Settings/getSettings', undefined, { root: true })
        accountId = settings?.accountId
        actorId = settings?.actorId
      }
      catch (err) {
        Console.log(`No settings for ${this.$appName} found on server`)
      }

      accountId ??= user.accounts?.map(account => account.id).sort()[0]
      actorId ??= user.accounts?.find(account => account.id == accountId)
        ?.actors
        ?.map(actor => actor.id)
        ?.sort()[0]

      if (!accountId) throw 'Could not assign AccountId from sso, settings or accounts list in user'
      if (!actorId)   throw 'Could not assign ActorId from sso, settings or actor list in user'


      // vuex state will be saved to localStorage, separate for each IdentityId
      await dispatch('setUniqueSignedInIdentifier', identityId)
      await dispatch('setPersistedState', identityId, {root: true})

      commit('IDENTITYID', identityId, {root: true})

      // Initiates ActiveActor plugin, which sets settings and account id
      commit('ACTORID', actorId, {root: true})

      return Promise.resolve()
    }
    catch(error) {
      Console.error('Could not signIn; explicitely signing out.', error)

      await dispatch('signOut')

      throw error
    }
  },

  async googleOAuth2SignIn({ commit }, $gAuth) {
    const googleUser = await $gAuth.signIn()

    commit('SET_LOGGED_IN_WITH_GOOGLE', true)

    return Promise.resolve({
      token: googleUser.getAuthResponse().access_token,
      //userId: googleUser.getId(), // permanent static id for user
      email: googleUser.getBasicProfile().getEmail()
    })
  },

  async googleOAuth2SignOut({ commit }, $gAuth) {

    const gAuth = this._vm.$gAuth

    if (! gAuth) {
      const error = 'gAuth object not available when attempting to sign out of google'
      Console.error(error)
      return Promise.reject(error)
    }

    const promise = await $gAuth.signOut()
    commit('SET_LOGGED_IN_WITH_GOOGLE', false)

    Console.log('Signed out of google')

    return promise
  },

  async setUniqueSignedInIdentifier ( n, uniqueIdentifier ) {
    localStorage.setItem(UNIQUE_SIGNED_IN_IDENTIFIER_KEY, uniqueIdentifier)
    return Promise.resolve()
  },

  async clearUniqueSignedInIdentifier() {
    localStorage.removeItem(UNIQUE_SIGNED_IN_IDENTIFIER_KEY)

    return Promise.resolve()
  },

  async createUser ( n, options ) {
    const identityTokens = [
      options.googleUserToken ? { type: 'oauth2_jwt', provider: 'google', token: options.googleUserToken } : undefined,
      options.password ? { type: 'password', username: options.username, password: options.password } : undefined,
    ].filter(item => item !== undefined)

    return await moondust.createUser({
      identityTokens: identityTokens,
      email: options.email,
    })
  },

  async authPhone ( { dispatch }, {phone, transactionType, transactionAmount} ) {
    //return { response: { data: { to: '+13852324674' } } }
    const res = await moondust.authPhone({phone, transactionType, transactionAmount})
    const verifiedPhone = res.data.to

    // Replace sent phone number with exact formatted number 
    // that authVerifyPhone will take

    await dispatch('Transaction/setVerifiedPhone', verifiedPhone, {root: true})

    return res
  },

  async authVerifyPhone ( n, { phone, code, transactionType, transactionAmount} ) {
    return await moondust.authVerifyPhone({ phone, code, transactionType, transactionAmount})
  },

  async signOut ( { state, commit, dispatch } ) {

    // Signout is triggered by 401 response from Advanstaff API
    // Do not signOut from moondust again if we've already done it

    Console.log('HAS SIGNOUT ALREADY BEEN triggered?', state.signoutTriggered)

    if (!state.signoutTriggered) {
      moondust.signOut()
    }

    commit('SET_SIGNOUT_TRIGGERED', true) // Set signoutForced to true

    await dispatch('clearState', undefined, {root: true})

    moondust.setActorId(undefined)

    if (state.loggedInWithGoogle) {
      dispatch('googleOAuth2SignOut')
    }

    if (state.loggedInWithPassword) {
      commit('SET_LOGGED_IN_WITH_PASSWORD', false)
    }

    return Promise.resolve()
  },

  async clearState( { dispatch, commit } ) {

    await dispatch('clearUniqueSignedInIdentifier')

    commit('CLEAR_STATE')

    return Promise.resolve()
  },
}

const mutations = {

  SET_LOGGED_IN_WITH_GOOGLE(state, boolean) {
    state.loggedInWithGoogle = boolean
  },

  SET_LOGGED_IN_WITH_PASSWORD(state, boolean) {
    state.loggedInWithPassword = boolean
  },

  SET_SIGNOUT_TRIGGERED(state, boolean) {
    state.signoutTriggered = boolean
  },

  REMOVE_TAKEN_USERNAMES(state) {
    state.takenUsernames = {}
  },

  REMOVE_TAKEN_USERNAME(state, username) {
    state.takenUsernames = {...state.takenUsernames, [username]: undefined}
  },

  SET_TAKEN_USERNAME(state, username) {
    state.takenUsernames = {...state.takenUsernames, [username]: true}
  },

  CLEAR_STATE(state) {
    state.foundEmployeeDigests = []
    state.takenUsernames = {}
    state.signoutTriggered = false

    state.loggedInWithGoogle = false
    state.loggedInWithPassword = false
  }
}

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state,
  getters,
  actions,
  mutations,
}

