const Console = {

  isDev: process.env.NODE_ENV != 'production',

  log() {
    // eslint-disable-next-line no-console
    if (this.isDev) console.log(...arguments)
  },

  error() {
    // eslint-disable-next-line no-console
    if (this.isDev) console.error(...arguments)
  },

  info() {
    // eslint-disable-next-line no-console
    if (this.isDev) console.info(...arguments)
  }
}

export default Console
