//import _ from 'lodash'

const getters = {
  account: (state, getters, rootState) => rootState.user?.accounts?.find(a => a.id == rootState.accountId),

  id: (state, getters) => getters.account.id,

  name: (state, getters) => getters.account?.name,
}

const actions = {
  clearState: () => Promise.resolve(),
}

export default {
  namespaced: true,
  getters,
  actions,
}

