import moondust from '@/services/moondust'
//import Console from '@/console'
//import _ from 'lodash'

const state = {
  transactionType: undefined,
  transactionAmount: undefined,
  phone: undefined,
  verifiedPhone: undefined,
  verificationCode: undefined,
  transferFundsResult: undefined,
}

const getters = {
}

const actions = {

  async resetTransaction({ commit }) {
    commit('CLEAR_STATE')
  },

  async setTransactionType({ commit }, transactionType) {
    commit('SET_TRANSACTION_TYPE', transactionType)
  },

  async setTransactionAmount({ commit }, transactionAmount) {
    commit('SET_TRANSACTION_AMOUNT', transactionAmount)
  },

  async setPhone({ commit }, phone) {
    commit('SET_PHONE', phone)
  },

  async setVerifiedPhone({ commit }, phone) {
    commit('SET_VERIFIED_PHONE', phone)
  },

  async setVerificationCode({ commit }, verificationCode) {
    commit('SET_VERIFICATION_CODE', verificationCode)
  },

  //async authVerifyPhone ( n, { phone, code } ) {
    //return await moondust.authVerifyPhone({ phone, code })
  //},

  async transferFunds( { state, commit } ) {

    const result = await moondust.transferFunds({
      transactionType: state.transactionType,
      transactionAmount: state.transactionAmount,
      phone: state.verifiedPhone,
    })

    commit('SET_TRANSFER_FUNDS_RESULT', result)

    return result
  },

  async clearState ({ commit }) {
    commit('CLEAR_STATE')

    return Promise.resolve()
  },
}

const mutations = {

  SET_TRANSACTION_TYPE(state, transactionType) {
    state.transactionType = transactionType
  },

  SET_TRANSACTION_AMOUNT(state, transactionAmount) {
    state.transactionAmount = transactionAmount
  },

  SET_PHONE(state, phone) {
    state.phone = phone
  },

  SET_VERIFIED_PHONE(state, verifiedPhone) {
    state.verifiedPhone = verifiedPhone
  },

  SET_VERIFICATION_CODE(state, verificationCode) {
    state.verificationCode = verificationCode
  },

  SET_TRANSFER_FUNDS_RESULT(state, result) {
    state.transferFundsResult = result
  },

  CLEAR_STATE(state) {
    state.transactionType = undefined
    state.transactionAmount = undefined
    state.phone = undefined
    state.verifiedPhone = undefined
    state.verificationCode = undefined
    state.transferFundsResult = undefined
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
